<template>
  <v-container>
    <v-data-table :headers="headers" :items="provincias" sort-by="desc">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Provincias</v-toolbar-title>
          <v-dialog v-model="dialog" max-width="500px">
            <v-container>
              <v-card>
                <v-card-title> Modificar Provincia </v-card-title>
                <validation-observer ref="observer" v-slot="{ invalid }">
                  <v-form @submit.prevent="submit" autocomplete="off">
                    <v-card-text>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Pais"
                        :rules="{
                          required: true
                        }"
                      >
                        <v-select
                          :items="paises"
                          label="Pais"
                          v-model="pais"
                          :error-messages="errors"
                          clearable
                          hide-selected
                          required
                          item-text="txtDesc"
                          item-value="id"
                          autocomplete="off"
                          ref="cmbPaises"
                        ></v-select>
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Provincia"
                        rules="required|min:4|max:50"
                      >
                        <v-text-field
                          v-model="editedItem.txtDesc"
                          :counter="50"
                          :error-messages="errors"
                          label="Provincia"
                          clearable
                          required
                          autocomplete="off"
                        ></v-text-field>
                      </validation-provider>
                    </v-card-text>
                    <v-card-actions>
                      <v-row>
                        <v-col>
                          <v-btn
                            class="mr-4"
                            type="submit"
                            block
                            plain
                            :disabled="invalid"
                          >
                            Guardar
                          </v-btn>
                        </v-col>
                        <v-col>
                          <v-btn block plain @click="close"> Cancelar </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-form>
                </validation-observer>
              </v-card>
            </v-container>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="80%">
            <v-container>
              <v-card>
                <v-card-title class="h6 text-center"
                  >¿Eliminar la provincia
                  {{
                    editedItem.txtDesc + "(" + editedItem.descPais + ")"
                  }}?</v-card-title
                >
                <v-card-actions>
                  <v-row>
                    <v-col>
                      <v-btn block plain @click="closeDelete"> No </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn
                        class="mr-4"
                        @click="deleteItemConfirm"
                        block
                        plain
                      >
                        Si
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-container>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn block plain @click="initialize"> Recargar </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { required, max, min, oneOf } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { mapState, mapActions } from "vuex";
import axios from "axios";

setInteractionMode("aggressive");

extend("required", {
  ...required,
  message: "{_field_} no puede quedar vacio",
});

extend("max", {
  ...max,
  message: "{_field_} no puede tener mas de {length} caractéres",
});

extend("min", {
  ...min,
  message: "{_field_} no puede tener menos de {length} caractéres",
});

extend("oneOf", {
  ...oneOf,
  message: "Debe seleccionar un item del combo.",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Provincia",
        align: "start",
        sortable: true,
        value: "txtDesc",
      },
      {
        text: "Pais",
        align: "start",
        sortable: true,
        value: "descPais",
      },
      { text: "Acciones", value: "actions", align: "end", sortable: false },
    ],
    provincias: [],
    paises: [],
    editedIndex: -1,
    editedItem: {
      id: null,
      txtDesc: "",
      idPais: null,
      descPais: "",
    },
    defaultItem: {
      id: null,
      txtDesc: "",
      idPais: null,
      descPais: "",
    },
  }),

  computed: {
    ...mapState(["usuario"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(["setAlerta"]),
    async initialize() {
      var response = await axios({
        url: process.env.VUE_APP_API + "parametrizacion/Provincias/-1",
        method: "GET",
        headers: { Authorization: "Bearer " + this.usuario.token },
      });
      this.provincias = await response.data.data;
      await this.cargaPaises();
    },
    async cargaPaises() {
      var response = await axios({
        url: process.env.VUE_APP_API + "parametrizacion/Paises",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + this.usuario.token,
        },
      });

      this.paises = response.data.data;
    },
    async editItem(item) {
      this.editedIndex = this.provincias.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.pais = await this.paises[
        this.paises.findIndex((i) => i.id === item.idPais)
      ];

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.provincias.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        var response = await axios({
          url: process.env.VUE_APP_API + "parametrizacion/provincia",
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + this.usuario.token,
          },
          data: { Id: this.editedItem.id },
        });
        if (response.data.estado == "OK") {
          this.setAlerta({
            snackbar_color: "success",
            text_snackbar:
              "Se elimino la provincia " +
              this.provincias[this.editedIndex].txtDesc.toUpperCase(),
            snackbar: true,
          });
          this.provincias.splice(this.editedIndex, 1);
          this.closeDelete();
        } else {
          this.setAlerta({
            snackbar_color: "warning",
            text_snackbar: response.data.data.error,
            snackbar: true,
          });
        }
      } catch (error) {
        this.setAlerta({
          snackbar_color: "danger",
          text_snackbar: error,
          snackbar: true,
        });
      }
    },

    close() {
      this.dialog = false;
      this.$refs.observer.reset();
      this.spais = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async submit() {
      try {
        if (this.editedIndex > -1) {
          this.$refs.observer.validate();
          var response = await axios({
            url: process.env.VUE_APP_API + "parametrizacion/provincia",
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: "Bearer " + this.usuario.token,
            },
            data: {
              Id: this.editedItem.id,
              txtDesc: this.editedItem.txtDesc,
              IdPais: this.pais,
            },
          });
          if (response.data.estado == "OK") {
            this.setAlerta({
              snackbar_color: "success",
              text_snackbar:
                "Se modifico la provincia " +
                this.provincias[this.editedIndex].txtDesc.toUpperCase() +
                " por " +
                this.editedItem.txtDesc.toUpperCase(),
              snackbar: true,
            });
            this.initialize();
          } else {
            this.setAlerta({
              snackbar_color: "warning",
              text_snackbar: response.data.data.error,
              snackbar: true,
            });
          }
        } else {
          this.provincias.push(this.editedItem);
        }
        this.close();
      } catch (error) {
        this.setAlerta({
          snackbar_color: "danger",
          text_snackbar: error,
          snackbar: true,
        });
      }
    },
  },
};
</script>